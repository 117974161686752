import React from 'react'
import { Link, navigate } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MetaImage from '../images/og/about.jpg'
import Img from 'gatsby-image'
import './who-we-are.less'

let text_style = { transform: 'translateX(0px)' }
let text_left = 0

let tc_start = 0
let tc_end = 0
// let tc_height = 1800
let tc_full = 0

class SecondPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      current: 1,
      img_left: '0',
      scrollY: 0,
      heading: 'hidden',
      tc_height: 1800,
    }
  }

  handle_scroll = (e) => {
    this.setState({
      scrollY: window.pageYOffset,
    })
  }

  window_h = () => {
    return typeof window !== 'undefined' ? window.innerHeight : 0
  }
  window_w = () => {
    return typeof window !== 'undefined' ? window.innerWidth : 0
  }

  componentDidMount = () => {
    document.body.classList.remove('stop-scroll')
    window.addEventListener('scroll', this.handle_scroll, false)
    window.scroll(0, 0)
    document.body.classList.add('remove')
    setTimeout(() => {
      document.body.classList.remove('cover', 'remove', 'white')
    }, 800)
    setTimeout(() => {
      this.setState({ heading: 'visible' })
    }, 100)

    if (typeof window !== 'undefined' && window.innerWidth > 640) {
      this.setState({ tc_height: 3100 })
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handle_scroll, false)
  }

  goTo = (e, link, white = false) => {
    e.preventDefault()
    if (white) {
      document.body.classList.add('cover', 'white')
    } else {
      document.body.classList.add('cover')
    }
    setTimeout(() => {
      navigate(link)
    }, 700)
  }

  componentDidUpdate = () => {
    if (
      typeof document !== 'undefined' &&
      document.getElementById('text_scroll_container') &&
      document.getElementById('text_scroll')
    ) {
      let text_length =
        document.getElementById('text_scroll_container').offsetWidth >=
        this.window_w()
          ? document.getElementById('text_scroll_container').offsetWidth -
            this.window_w()
          : 0

      tc_start = document.getElementById('text_scroll')
        ? document.getElementById('text_scroll').offsetTop
        : 0
      tc_end = document.getElementById('text_scroll')
        ? document.getElementById('text_scroll').offsetTop + text_length
        : 0
      const tc_height = text_length + this.window_h()
      tc_full = text_length
      if (this.state.tc_height !== tc_height) {
        this.setState({ tc_height })
      }
    }
  }

  render() {
    /**
     * Start Text scrolling functions
     */

    // setting the variables, scroll start, end and height of the container
    let text_scroll = false

    // setting the styles to animate
    if (this.state.scrollY < tc_start) {
      text_style = { transform: `translateX(0px)` }
      text_scroll = false
    } else if (this.state.scrollY > tc_end) {
      text_style = {
        transform: `translateX(-${tc_full}px)`,
        top: 'auto',
        bottom: '0',
      }
      text_scroll = false
    } else if (this.state.scrollY > tc_start) {
      text_left = this.state.scrollY - tc_start
      text_style = { transform: `translateX(-${text_left}px)` }
      text_scroll = true
    }

    return (
      <Layout>
        <SEO
          title="Studio"
          image={MetaImage}
          description="We are creative agents of change. Learn more about who we are and how we help companies of all sizes bring their vision to life."
        />

        <section className="who-we-are hero" id="hero">
          <h1 className={this.state.heading}>
            <span>We are creative agents of change</span>
          </h1>
        </section>

        <section className="who-we-are images">
          <div
            className="uk-position-relative uk-visible-toggle uk-light"
            tabIndex="-1"
            data-uk-slider="finite: true;"
          >
            <ul className="uk-slider-items uk-grid uk-grid-large">
              <li className="uk-flex uk-flex-middle uk-width-2-3 uk-width-2-5@s">
                <Img
                  fluid={this.props.data.image1.childImageSharp.fluid}
                  alt="Enso Digital Studio"
                />
              </li>
              <li className="uk-flex uk-flex-middle uk-width-2-3 uk-width-2-5@s">
                <Img
                  fluid={this.props.data.image2.childImageSharp.fluid}
                  alt="Enso Digital Studio"
                />
              </li>
              <li className="uk-flex uk-flex-middle uk-width-2-3 uk-width-2-5@s">
                <Img
                  fluid={this.props.data.image3.childImageSharp.fluid}
                  alt="Enso Digital Studio"
                />
              </li>
              <li className="uk-flex uk-flex-middle uk-width-2-3 uk-width-2-5@s">
                <Img
                  fluid={this.props.data.image4.childImageSharp.fluid}
                  alt="Enso Digital Studio"
                />
              </li>
              <li className="uk-flex uk-flex-middle uk-width-2-3 uk-width-2-5@s">
                <Img
                  fluid={this.props.data.image5.childImageSharp.fluid}
                  alt="Enso Digital Studio"
                />
              </li>
            </ul>
          </div>
        </section>

        <section
          className="who-we-are text-scroll black-bg"
          id="text_scroll"
          style={{ height: `${this.state.tc_height}px` }}
        >
          <div
            className={text_scroll ? 'inner fixed' : 'inner'}
            id="text_scroll_container"
            style={text_style}
          >
            <blockquote className="white-text">
              We envision, design, and build exceptional digital experiences.
            </blockquote>
          </div>
        </section>

        <div className="who-we-are-intro-text black-bg">
          <div className="bg-image uk-visible@l"></div>
          <div className="text">
            <div className="uk-grid uk-grid-collapse">
              <div className="uk-width-5-6@s uk-width-2-3@m uk-margin-auto">
                <p>
                  We're a small team full of big ideas with a shared passion for
                  building world-class digital products. We believe the best
                  experiences keep complexity behind the scenes and have
                  interfaces that are crafted with even the smallest of details
                  in mind.
                </p>

                <p>
                  Our goal is to help companies of all sizes bring their vision
                  to life. We do this by transforming ideas into products that
                  are simple, engaging, and easy to use.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className="we-build">
          <div className="inner">
            <div className="uk-container">
              <div className="uk-grid uk-flex">
                <div className="uk-width-5-6@m uk-margin-auto">
                  <h1>Products from end-to-end.</h1>

                  <div className="uk-grid">
                    <div className="uk-width-2-3@s content">
                      <p>
                        We don't just create great looking interfaces. Enso
                        Digital is a multidisciplinary design studio. We blend
                        technical expertise with an aptitude for beautiful
                        design to create digital experiences for people who care
                        about detail and craft.
                      </p>

                      <p>
                        We work with companies to build digital products from
                        end-to-end, bringing even the smallest of ideas to
                        screens of all sizes.
                      </p>
                    </div>

                    <div className="uk-width-1-3@s">
                      <ul className="nav">
                        <li>Web Apps</li>
                        <li>Websites</li>
                        <li>User Experience Design</li>
                        <li>User Interface Design</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bottom-cta who-we-are">
          <div>
            <div className="link">
              <Link
                onClick={(e) => this.goTo(e, '/lets-talk', true)}
                to="/lets-talk"
              >
                Let's Talk
              </Link>
              <span>Contact</span>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default SecondPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "who-we-are/who-we-are-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "who-we-are/who-we-are-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "who-we-are/who-we-are-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "who-we-are/who-we-are-4.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "who-we-are/who-we-are-5.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
